<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div class="flex flex-wrap align-items marginBottom20">

          <div class="flex align-items marginRight10 marginBottom10">
            <!-- <span class="span marginRight10">优惠券名称: </span> -->
            <div class="width250">
              <el-input
                v-model="searchData.code"
                placeholder="输入售后编号/订单编号"
                @keyup.enter.native="handleSearch"
                clearable
                maxlength="20"
              ></el-input>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <!-- <span class="span marginRight10">优惠券名称: </span> -->
            <div class="width300">
              <el-input
                v-model="searchData.name"
                placeholder="输入会员编号/会员昵称/会员手机号"
                @keyup.enter.native="handleSearch"
                clearable
                maxlength="20"
              ></el-input>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <!-- <span class="span marginRight10">优惠券名称: </span> -->
            <div class="width250">
              <el-input
                v-model="searchData.car"
                placeholder="输入车型名称/车辆编号"
                @keyup.enter.native="handleSearch"
                clearable
                maxlength="20"
              ></el-input>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <!-- <span class="span marginRight10">下单时间：</span> -->
            <div class="width200">
              <el-select
                class="width250"
                v-model="searchData.type"
                clearable
                @change="handleSearch"
              >
                <el-option
                  value=""
                  label="请选择售后原因"
                ></el-option>
                <el-option
                  :value="1"
                  label="车辆故障"
                ></el-option>
                <!-- <el-option
                  :value="2"
                  label="其他原因"
                ></el-option> -->
              </el-select>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <!-- <span class="span marginRight10">下单时间：</span> -->
            <div class="width200">
              <el-select
                class="width250"
                v-model="searchData.handleWay"
                clearable
                @change="handleSearch"
              >
                <el-option
                  value=""
                  label="请选择售后类型"
                ></el-option>
                <el-option
                  :value="1"
                  label="换车"
                ></el-option>
                <el-option
                  :value="2"
                  label="退款"
                ></el-option>
              </el-select>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <!-- <span class="span marginRight10">下单时间：</span> -->
            <div class="width200">
              <el-select
                class="width250"
                v-model="searchData.state"
                clearable
                @change="handleSearch"
              >
                <el-option
                  value=""
                  label="请选择售后状态"
                ></el-option>
                <el-option
                  :value="1"
                  label="待审核"
                ></el-option>
                <el-option
                  :value="2"
                  label="审核通过"
                ></el-option>
                <el-option
                  :value="3"
                  label="审核驳回"
                ></el-option>
              </el-select>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <!-- <span class="span marginRight10">下单时间：</span> -->
            <div class="width300">
              <el-date-picker
                v-model="searchData.createTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="handleSearch"
                clearable
              >
              </el-date-picker>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <el-button
              size="medium"
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
              class="marginLeft10"
            >查询</el-button>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <el-button
              size="medium"
              type="info"
              icon="el-icon-refresh-right"
              @click="handleReset"
              class="marginLeft10"
            >重置</el-button>

          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <el-button
              size="medium"
              type="primary"
              icon="el-icon-plus"
              @click="handleExport"
            >导出</el-button>
          </div>

        </div>
      </template>

      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <el-button
          v-if="row.state ===1"
          type="text"
          size="mini"
          @click="handleIdeaHandle(row)"
        >审核</el-button>
        <el-button
          v-if="row.state !==1"
          type="text"
          size="mini"
          @click="handleDetails(row)"
        >详情</el-button>
      </template>
    </avue-crud>

  </div>
</template>

<script>
import { list } from "@/api/sale/manage";
import dayjs from "dayjs";
import qs from "qs";
export default {
  name: "index",
  data() {
    return {
      commitStateLoading1: false,
      commitStateLoading2: false,
      searchData: {
        name: "",
        car: "",
        code: "",
        handleWay: "",
        state: "",
        type: "",
        createTime: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      CarTypeDescData: {},
      tableData: [],
      showLoading: false,
      option: {
        title: "售后列表",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "售后编号",
            prop: "code",
          },
          {
            label: "订单编号",
            prop: "orderCode",
          },
          {
            label: "会员编号",
            prop: "userCode",
          },
          {
            label: "会员昵称",
            prop: "nickname",
          },
          {
            label: "会员手机号",
            prop: "phone",
          },
          {
            label: "车型名称",
            prop: "carTypeName",
          },
          {
            label: "车辆编号",
            prop: "carCode",
          },
          {
            label: "售后类型",
            prop: "handleWay",
            formatter: function (row, column, cellValue) {
              return row.handleWay === 1
                ? "换车"
                : row.handleWay === 2
                ? "退款"
                : row.handleWay;
            },
          },
          {
            label: "售后原因",
            prop: "type",
            formatter: function (row, column, cellValue) {
              return row.type === 1
                ? "车辆故障"
                : row.type === 2
                ? "其他原因"
                : row.type;
            },
          },
          {
            label: "申请时间",
            prop: "createTime",
          },
          {
            label: "售后状态",
            prop: "state",
            formatter: function (row, column, cellValue) {
              return row.state === 1
                ? "待审核"
                : row.state === 2
                ? "审核通过"
                : row.state === 3
                ? "审核驳回"
                : row.state;
            },
          },
          {
            label: "退款金额（元）",
            prop: "refundPrice",
            formatter: function (row, column, cellValue) {
              return row.state === 1 || row.state === 3 ? '-' : cellValue
            },
          },
        ],
      },
      dialogTableVisibleUse: false,
      form: {
        id: "",
        handleSuggest: "",
      },
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 0,
        column: [
          {
            label: "",
            prop: "handleSuggest",
            span: 24,
            maxlength: 200,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入处理意见",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
      if (
        searchForm.searchData.createTimeStart &&
        searchForm.searchData.createTimeEnd
      ) {
        this.searchData.createTime = [
          searchForm.searchData.createTimeStart,
          searchForm.searchData.createTimeEnd,
        ];
      }
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  mounted() {
    const that = this;
    // window.setInterval(() => {
    //   setTimeout(() => {
    //     that.onLoad();
    //   }, 100);
    // }, 30000);
  },
  methods: {
    onLoad() {
      this.showLoading = true;
      const searchData = {
        name: this.searchData.name,
        car: this.searchData.car,
        code: this.searchData.code,
        handleWay: this.searchData.handleWay,
        state: this.searchData.state,
        type: this.searchData.type,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }

      const searchForm = {
        path: this.$route.path,
        searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify({ ...searchForm }));

      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      // console.log('重置')
      this.searchData = {
        name: "",
        car: "",
        code: "",
        handleWay: "",
        state: "",
        type: "",
        createTime: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },

    // 审核
    handleIdeaHandle(row) {
      this.$router.push({
        path: "/sale/manage/ideaHandle",
        query: {
          type: "details",
          id: row.id,
        },
      });
    },
    // 查看处理
    handleDetails(row) {
      this.$router.push({
        path: "/sale/manage/details",
        query: {
          type: "details",
          id: row.id,
        },
      });
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        handleSuggest: "",
      };
    },
    uploadDelete(file, column) {
      return this.$confirm(`是否确定移除？`);
    },
    uploadError(error, column) {
      error && this.$message.error(error);
    },
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadAfter(res, done, loading, column) {
      done();
      if (!res.path) {
        let msg = JSON.parse(JSON.stringify(res)).message;
        if (msg.indexOf("401") > -1) {
          this.$message.error("登录失效，请重新登录");
          this.$router.push("/login");
        } else {
          this.$message.error("上传失败");
        }
        loading();
      } else {
        column.propsHttp.name = res.path;
      }
    },
      // 导出
      handleExport() {
      const searchData = {
        name:this.searchData.name,
        car: this.searchData.car,
        code: this.searchData.code,
        handleWay:this.searchData.handleWay,
        state: this.searchData.state,
        type: this.searchData.type,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      window.open(
        `/api/web/sale/manage/export?${qs.stringify(searchData)}`,
        "_self"
      );
    },
  },
};
</script>

<style scoped>
.travel .travel-list {
  width: 33%;
  text-align: center;
  border-left: 1px solid #999;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
}

.travel .travel-list:last-child {
  border-right: 1px solid #999;
}

.travel .travel-list .travel-list-title {
  border-bottom: 1px solid #999;
}

.avue-crud .el-input--small input,
::v-deep .avue-form .travel .travel-list .el-input--small input {
  border: none;
}
</style>